import React, { useState, useEffect, useRef } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null
import { isBrowser } from "../../../context/ApolloContext"
import ReactPlayer from "react-player"
const $ = isBrowser ? require("jquery") : {}

Modal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    padding: 0,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    margin: "auto",
    padding: 0,
    border: "none",
    borderRadius: 0,
    width: "100%",
    height: "100%",
  },
}

const SolutionVideoSliderPopup = ({ intl, data, open, video, closeHandle }) => {
  const [prevNext, setprevState] = useState(null)
  const [IsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (video) {
      $(".card").removeClass("is-selected")
      $(".card")
        .eq(video)
        .addClass("is-selected")
    }
    open ? openModal() : closeModal()
  })

  const flickityOptions = {
    freeScroll: false,
    pageDots: false,
    wrapAround: true,
    prevNextButtons: true,
    lazyLoad: true,
    initialIndex: video,
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    isBrowser &&
      prevNext &&
      prevNext.on("change", () => {
        $(".custom-video-slider")
          .find(".card video")
          .each(function(i, video) {
            video.pause()
          })
      })
  }, [prevNext])

  return (
    <Modal
      isOpen={IsOpen}
      style={customStyles}
      contentLabel="Modal"
      onRequestClose={() => closeHandle(false)}
    >
      <div
        style={{
          margin: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          padding: "44px 0",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            padding: 5,
            zIndex: 9,
          }}
          onClick={() => closeHandle(false)}
        >
          <MdClose color="white" size={30} style={{ zIndex: 9 }} />
        </div>

        <Carousel className={"custom-video-slider"}>
          <div style={{ width: "100%" }}>
            <Flickity
              flickityRef={c => setprevState(c)}
              options={flickityOptions}
              className={"carousel"}
              lazyLoad={true}
            >
              {data.map((item, index) => {
                return (
                  item && (
                    <Card key={index.toString()} className="card">
                      <IframeBox order={index}>
                        <Image src="https://static.percko.com/uploads/3fa56524-97ec-4f62-9b9e-9d0d629e26ed.jpg" />
                        <ReactPlayer
                          url={item.video_url}
                          playing={false}
                          controls={true}
                          width="100%"
                          height="100%"
                        />
                      </IframeBox>
                    </Card>
                  )
                )
              })}
            </Flickity>
          </div>
        </Carousel>
      </div>
    </Modal>
  )
}

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;

  @media (max-width: 850px) {
    padding: 0px;
  }
`

const IframeBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  iframe,
  video {
    width: 80% !important;
    height: auto !important;
    width: 80%;
    max-width: 350px;
    // max-height: 80%;
    margin: 0px;
    aspect-ratio: 9 / 16;
  }
`

const Image = styled.img`
  visibility: hidden;
  opacity: 0;
  width: 80%;
  max-width: 350px;
  // max-height: 80%;
  margin: 0px;
  aspect-ratio: 9 / 16;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;

  .flickity-prev-next-button {
    background-color: transparent;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    z-index: 3;

    @media (max-width: 600px) {
      width: 15px;
      height: 15px;
      border: 0px solid #111;
    }

    :hover {
      background-color: #111;
      color: #fff;

      @media (max-width: 600px) {
        background-color: transparent;
        color: #111;
      }
    }

    > .flickity-button-icon {
      @media (max-width: 600px) {
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .flickity-prev-next-button.next {
    @media (max-width: 600px) {
      right: 15px;
    }
  }
  .flickity-prev-next-button.previous {
    @media (max-width: 600px) {
      left: 15px;
    }
  }
`

export default injectIntl(SolutionVideoSliderPopup)
