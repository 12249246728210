import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import SolutionVideoSliderPopup from "../SolutionVideoSliderPopup"

const SolutionTalkingSectionWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: center;
  // margin-top: 10px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const LynePageSectionListContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h2`
  font-size: 32px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 32px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
`

const Imagebox = styled.div`
  width: 100%;
  max-width: 95px;
  display: flex;
  aspect-ratio: 1 / 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const VideoPlayIcon = styled.img`
  width: 100%;
  max-width: 22px;
  object-fit: contain;
  margin: 0px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Text = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 12px;
  line-height: 1.2;
  color: #262626;
  margin: 10px 0px 0px;
  text-align: center;
`

const SolutionTalkingVideosSection = ({ intl, data, mobile }) => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [videoOpen, setvideoOpen] = useState(null)

  const handleVideo = index => {
    setmodalOpen(!modalOpen)
    setvideoOpen(index)
  }
  return (
    data && (
      <SolutionTalkingSectionWrapper mobile={mobile}>
        {data.list && (
          <SolutionVideoSliderPopup
            data={data.list}
            open={modalOpen}
            video={videoOpen}
            closeHandle={() => setmodalOpen(false)}
          />
        )}
        <LynePageSectionListContainer>
          {data.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
          )}
          {data.list && (
            <Row>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <Column key={index.toString()}>
                      {item.image && (
                        <Imagebox onClick={() => handleVideo(index)}>
                          <Image
                            className="lazyload"
                            data-src={item.image}
                            alt={item.image_alt ? item.image_alt : ""}
                          />
                          <VideoPlayIcon
                            className="lazyload"
                            data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                          />
                        </Imagebox>
                      )}
                      {item.text && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </Column>
                  )
                )
              })}
            </Row>
          )}
        </LynePageSectionListContainer>
      </SolutionTalkingSectionWrapper>
    )
  )
}

export default injectIntl(SolutionTalkingVideosSection)
